<template>
    <section id="user-profile">
        <div class="grid-nogutter">
            <div class="col-12">
              <Toast />
                <div class="grid">
                    <div class="col-12 md:col-6 lg:col-4">
                        <div class="card">
                            <div class="grid">
                                <div class="col-12">
                                    <Skeleton height="24rem" width="100%" v-if="pageLoading"/>
                                    <template v-else>
                                        <img class="w-full" :src="constant.imgUrl + user.photo + '?' + new Date()" alt="Profile photo" v-if="user.photo != null" />
                                        <img class="w-full" src="../assets/images/user.png" alt="Profile photo" v-else />
                                    </template>
                                </div>
                            </div>
                            <div class="mt-2">
                                <Skeleton class="flex" height="2.75rem" v-if="pageLoading" />
                                <div class="uppercase" v-else>
                                    <Button label="Change photo" icon="pi pi-image" class="p-button-raised p-button-text w-full mt-auto" @click="changePhotoDialog = true" />
                                </div>
                            </div>
                            <div class="col-12 md:col-12 mt-4" v-if="pageLoading">
                                <Skeleton class="mb-2" height="4rem"></Skeleton>
                                <Skeleton height="2rem"></Skeleton>
                            </div>
                            <div class="flex justify-content-center mt-4" v-else>
                                <div class="flex flex-column align-items-center">
                                    <div class="flex">
                                        <Skeleton height="3rem" width="100%" v-if="pageLoading"/>
                                        <div class="flex text-2xl text-cyan-900" v-else>{{ user.name }}</div>
                                    </div>
                                    <div class="flex mt-2">
                                        <Skeleton height="2rem" width="100%" v-if="pageLoading"/>
                                        <div class="flex text-cyan-700" v-else>{{ user.email }}</div>
                                    </div>
                                    <!-- <div class="flex mt-2" v-if="user != null && user.verified == 0">
                                        <div class="flex">
                                            <div class="flex">
                                                <InlineMessage severity="warn">Email not verified!</InlineMessage>
                                            </div>
                                            <div class="flex">
                                                <Button class="p-button-warning p-button-sm" label="Verify now" @click="sendVerificationEmail" :loading="verificationLoading" />
                                            </div>
                                        </div>
                                    </div> -->
                                </div>
                            </div>
                            <!-- <div class="card p-2 my-2">
                                <div class="col-12 md:col-12" v-if="pageLoading">
                                    <div class="grid">
                                        <div class="p-field col-6 md:col-4"><Skeleton height="4rem"></Skeleton></div>
                                        <div class="p-field col-6 md:col-4"><Skeleton height="4rem"></Skeleton></div>
                                        <div class="p-field col-6 md:col-4"><Skeleton height="4rem"></Skeleton></div>
                                    </div>
                                </div>
                                <div class="flex justify-content-evenly uppercase font-semibold mt-4" v-else>
                                    <div class="flex">
                                        <div class="flex flex-column align-items-center">
                                            <div class="flex text-2xl text-cyan-600">32</div>
                                            <div class="flex text-cyan-300">Places</div>
                                        </div>
                                    </div>
                                    <div class="flex">
                                        <div class="flex flex-column align-items-center">
                                            <div class="flex text-2xl text-cyan-600">22</div>
                                            <div class="flex text-cyan-300">Tours</div>
                                        </div>
                                    </div>
                                    <div class="flex">
                                        <div class="flex flex-column align-items-center">
                                            <div class="flex text-2xl text-cyan-600">12</div>
                                            <div class="flex text-cyan-300">Agencies</div>
                                        </div>
                                    </div>
                                </div>
                            </div> -->
                            <!-- <div class="mt-4">
                                <div class="my-2">
                                    <Skeleton class="flex" height="2.75rem" v-if="pageLoading" />
                                    <div class="uppercase" v-else>
                                        <Button label="My tours" icon="pi pi-map" class="p-button-raised p-button-info w-full mt-auto" />
                                    </div>
                                </div>
                                <div class="my-2">
                                    <Skeleton class="flex" height="2.75rem" v-if="pageLoading" />
                                    <div class="uppercase" v-else>
                                        <Button label="My bookings" icon="pi pi-ticket" class="p-button-raised p-button-info w-full mt-auto" />
                                    </div>
                                </div>
                            </div> -->
                        </div>
                    </div>
                    <div class="col-12 md:col-6 lg:col-8">
                        <div class="card">
                            <Divider align="left" class="mb-4">
                                <b class="uppercase text-500">Personal Information</b>
                            </Divider>
                            <form @submit.prevent="savePersonalInfo(!personal$.$invalid)">
                                <div class="p-fluid grid">
                                    <div class="field col-12 md:col-6">
                                        <Skeleton height="3.4rem" v-if="pageLoading"/>
                                        <span class="p-float-label p-fluid" v-else>
                                            <InputText
                                                id="name"
                                                type="text"
                                                :class="{ 'p-invalid': personal$.name.$invalid && personalInfoSubmitted, }"
                                                v-model="personal$.name.$model"
                                            />
                                            <label for="name">Name</label>
                                        </span>
                                        <span v-if="personal$.name.$error && personalInfoSubmitted">
                                            <span
                                                id="name-error"
                                                v-for="(error, index) of personal$.name.$errors"
                                                :key="index"
                                            >
                                                <small class="p-error">{{error.$message.replace("Value", "Name")}}</small>
                                            </span>
                                        </span>
                                        <small
                                            v-else-if="(personal$.name.$invalid && personalInfoSubmitted) || personal$.name.$pending.$response"
                                            class="p-error"
                                        >
                                            {{ personal$.name.required.$message.replace("Value", "Name") }}
                                        </small>
                                    </div>
                                    <div class="field col-12 md:col-6">
                                        <Skeleton height="3.4rem" v-if="pageLoading"/>
                                        <span class="p-float-label p-fluid" v-else>
                                            <InputText
                                                id="mobile"
                                                type="text"
                                                v-model="personalInfo.mobile"
                                            />
                                            <label for="mobile">Mobile</label>
                                        </span>
                                    </div>
                                    <div class="field col-12 md:col-6">
                                        <Skeleton height="3.4rem" v-if="pageLoading"/>
                                        <span class="p-float-label p-fluid" v-else>
                                            <Calendar
                                                id="dob"
                                                v-model="personalInfo.dob"
                                                dateFormat="dd-mm-yy"
                                                :showIcon="true"
                                                :showButtonBar="true"
                                            />
                                            <label for="dob">Birthdate</label>
                                        </span>
                                    </div>
                                    <div class="field col-12 md:col-6">
                                        <Skeleton height="3.4rem" v-if="pageLoading"/>
                                        <span class="p-float-label p-fluid" v-else>
                                            <InputText
                                                id="nid"
                                                type="text"
                                                v-model="personalInfo.nid"
                                            />
                                            <label for="nid">NID</label>
                                        </span>
                                    </div>
                                    <div class="field col-12 md:col-6">
                                        <Skeleton height="3.4rem" v-if="pageLoading"/>
                                        <span class="p-float-label p-fluid" v-else>
                                            <InputText
                                                id="passport"
                                                type="text"
                                                v-model="personalInfo.passport"
                                            />
                                            <label for="passport">Passport</label>
                                        </span>
                                    </div>
                                    <div class="field col-12">
                                        <div class="flex justify-content-end">
                                            <div class="flex">
                                                <Skeleton height="2.75rem" width="10rem" v-if="pageLoading"/>
                                                <Button class="p-button-raised p-button-text" type="submit" label="Save changes" icon="pi pi-check" v-else :loading="personalInfoLoading" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div class="card">
                            <Divider align="left" class="mb-4">
                                <b class="uppercase text-500">Account Information</b>
                            </Divider>
                            <form @submit.prevent="saveAccountInfo(!personal$.$invalid)">
                                <div class="p-fluid grid">
                                    <div class="field col-12 md:col-6">
                                        <Skeleton height="3.4rem" v-if="pageLoading"/>
                                        <span class="p-float-label p-fluid" v-else>
                                            <InputText
                                                id="email"
                                                type="text"
                                                v-model="personalInfo.email"
                                                disabled
                                            />
                                            <label for="email">Email</label>
                                        </span>
                                    </div>
                                    <div class="field col-12 md:col-6">
                                        <Skeleton height="3.4rem" v-if="pageLoading"/>
                                        <span class="p-float-label p-fluid" v-else>
                                            <Password
                                                id="password"
                                                type="text"
                                                :class="{ 'p-invalid': account$.password.$invalid && accountInfoSubmitted, }"
                                                v-model="account$.password.$model"
                                            />
                                            <label for="password">Password</label>
                                        </span>
                                        <span v-if="account$.password.$error && accountInfoSubmitted">
                                            <span
                                                id="password-error"
                                                v-for="(error, index) of account$.password.$errors"
                                                :key="index"
                                            >
                                                <small class="p-error">{{error.$message.replace("Value", "Password")}}</small>
                                            </span>
                                        </span>
                                        <small
                                            v-else-if="(account$.password.$invalid && accountInfoSubmitted) || account$.password.$pending.$response"
                                            class="p-error"
                                        >
                                            {{ account$.password.required.$message.replace("Value", "Password") }}
                                        </small>
                                    </div>
                                    <div class="field col-12">
                                        <div class="flex justify-content-end">
                                            <div class="flex">
                                                <Skeleton height="2.75rem" width="10rem" v-if="pageLoading"/>
                                                <Button class="p-button-raised p-button-text" type="submit" label="Save password" icon="pi pi-check" v-else :loading="accountInfoLoading" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div class="card">
                            <Divider align="left" class="mb-4">
                                <b class="uppercase text-500">Contact Information</b>
                            </Divider>
                            <form @submit.prevent="saveContactInfo(!contact$.$invalid)">
                                <div class="p-fluid grid">
                                    <div class="field col-12 md:col-6">
                                        <Skeleton height="3.4rem" v-if="pageLoading"/>
                                        <span class="p-float-label p-fluid" v-else>
                                            <Dropdown
                                                id="country"
                                                :options="countryList"
                                                optionLabel="value"
                                                optionValue="value"
                                                v-model="contactInfo.country"
                                                :loading="countryLoading"
                                                @change="countryChanged"
                                            />
                                            <label for="country">Country</label>
                                        </span>
                                    </div>
                                    <div class="field col-12 md:col-6">
                                        <Skeleton height="3.4rem" v-if="pageLoading"/>
                                        <span class="p-float-label p-fluid" v-else>
                                            <Dropdown
                                                id="division"
                                                :options="divisionList"
                                                optionLabel="value"
                                                optionValue="value"
                                                v-model="contactInfo.division"
                                                :filter="true"
                                                :loading="divisionLoading"
                                                @change="divisionChanged"
                                            />
                                            <label for="division">Division</label>
                                        </span>
                                    </div>
                                    <div class="field col-12 md:col-6">
                                        <Skeleton height="3.4rem" v-if="pageLoading"/>
                                        <span class="p-float-label p-fluid" v-else>
                                            <Dropdown
                                                id="district"
                                                :options="districtList"
                                                optionLabel="value"
                                                optionValue="value"
                                                v-model="contactInfo.district"
                                                :filter="true"
                                                :loading="districtLoading"
                                                @change="districtChanged"
                                            />
                                            <label for="district">District</label>
                                        </span>
                                    </div>
                                    <div class="field col-12 md:col-6">
                                        <Skeleton height="3.4rem" v-if="pageLoading"/>
                                        <span class="p-float-label p-fluid" v-else>
                                            <Dropdown
                                                id="thana"
                                                :options="thanaList"
                                                optionLabel="value"
                                                optionValue="value"
                                                v-model="contactInfo.thana"
                                                :filter="true"
                                                :loading="thanaLoading"
                                            />
                                            <label for="thana">Thana</label>
                                        </span>
                                    </div>
                                    <div class="field col-12">
                                        <Skeleton height="3.4rem" v-if="pageLoading"/>
                                        <span class="p-float-label p-fluid" v-else>
                                            <InputText
                                                id="line"
                                                type="text"
                                                v-model="contactInfo.line"
                                            />
                                            <label for="line">Address line</label>
                                        </span>
                                    </div>
                                    <div class="field col-12">
                                        <div class="flex justify-content-end">
                                            <div class="flex">
                                                <Skeleton height="2.75rem" width="10rem" v-if="pageLoading"/>
                                                <Button class="p-button-raised p-button-text" type="submit" label="Save address" icon="pi pi-check" v-else :loading="personalInfoLoading" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Dialog header="Update profile photo" v-model:visible="changePhotoDialog" :breakpoints="{'960px': '90vw'}" :style="{width: '40vw'}" :modal="true" @hide="closePhotoDialog">
            <div class="flex justify-content-center" v-if="photoToCrop == null">
                <div class="flex align-items-center justify-content-center">
                    <img
                        class="flex img-responsive"
                        height="500"
                        width="500"
                        :src="user.photo != null ? constant.imgUrl + user.photo + '?' + new Date() : 'images/user.png'"
                        alt="Profile photo"
                    />
                </div>
            </div>
            <BlockUI :blocked="profilePhotoLoading" v-else>
                <div class="flex justify-content-center">
                    <Cropper
                        class="flex w-full"
                        :src="photoToCrop"
                        :stencil-component="$options.components.CircleStencil"
                        :stencil-props="{
                            aspectRatio: 1/1
                        }"
                        @change="onPhotoChange"
                        v-if="photoToCrop"
                    />
                </div>
            </BlockUI>
            <template #footer>
                <div class="flex justify-content-center mb-4">
                    <template v-if="photoToCrop">
                        <Button class="flex p-button-secondary p-button-raised" label="Discard" icon="pi pi-times" @click="discardPhoto" :disabled="profilePhotoLoading" />
                        <Button class="flex p-button-raised" label="Save New" icon="pi pi-check" @click="uploadPhoto" :disabled="profilePhotoLoading" />
                    </template>
                    <template v-else>
                        <FileUpload class="flex p-button-raised" chooseLabel="Select new photo" mode="basic" v-model="selectedPhoto" accept="image/*" :maxFileSize="1000000" @select="onPhotoSelect" />
                    </template>
                </div>
            </template>
        </Dialog>
    </section>
</template>

<script>
import { ref, reactive } from "vue";
// import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { required, minLength } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import GlobalValues from '../data/GlobalValues';
import Constant from '../data/constant';
import { useToast } from 'primevue/usetoast';
import moment from 'moment';
import {  Cropper } from 'vue-advanced-cropper';
import 'vue-advanced-cropper/dist/style.css';
import Axios from 'axios';


export default {
    components: {
		Cropper
	},
    setup: () => {
        // const store = useStore();
        const router = useRouter();
        const toast = useToast();
        const globalValues = ref(new GlobalValues());
        const constant =  ref(new Constant());

        const pageLoading = ref(true);
        const verificationLoading = ref(false);
        const personalInfoLoading = ref(false);
        const contactInfoLoading = ref(false);
        const accountInfoLoading = ref(false);
        const profilePhotoLoading = ref(false);

        const changePhotoDialog = ref(false);

        const countryLoading = ref(false);
        const divisionLoading = ref(false);
        const districtLoading = ref(false);
        const thanaLoading = ref(false);
        const user = ref();

        const personalInfoSubmitted = ref(false);
        const personalInfoShowMessage = ref(false);
        const contactInfoSubmitted = ref(false);
        const contactInfoShowMessage = ref(false);
        const accountInfoSubmitted = ref(false);
        const accountInfoShowMessage = ref(false);

        const personalInfo = reactive({
            name: "",
            email: "",
            mobile: "",
            dob: null,
            nid: "",
            passport: "",
        });
        const personalInfoRules = {
            name: { required, minLength: minLength(4) },
        };
        const personal$ = useVuelidate(personalInfoRules, personalInfo);

        const accountInfo = reactive({
            password: "",
        });
        const passwordRules = {
            password: { required, minLength: minLength(6) },
        };
        const account$ = useVuelidate(passwordRules, accountInfo);

        const countryList = ref([]);
        const divisionList = ref([]);
        const districtList = ref([]);
        const thanaList = ref([]);

        const contactInfo = reactive({
            country: "",
            division: "",
            district: "",
            thana: "",
            line: "",
        });
        const contactInfoRules = {
            country: { required },
            division: { required },
            district: { required },
            thana: { required },
        };
        const contact$ = useVuelidate(contactInfoRules, contactInfo);

        const photo = ref(null);
        const selectedPhoto = ref(null);
        const photoToCrop = ref(null);

        const getUserData = () => {
            Axios
                .get("api/user")
                .then((res) => {
                    pageLoading.value = false;
                    if(res.data.response == "success"){
                        user.value = res.data.data;
                        personalInfo.name = res.data.data.name;
                        personalInfo.email = res.data.data.email;
                        personalInfo.mobile = res.data.data.mobile ? formatMobileNumber(res.data.data.mobile) : '';
                        if(res.data.data.dob != null){
                            personalInfo.dob = new Date(res.data.data.dob);
                        }
                        personalInfo.nid = res.data.data.nid;
                        personalInfo.passport = res.data.data.passport;

                        getCountryList();
                        if(res.data.data.address != null){
                            contactInfo.country = res.data.data.address.country;
                            contactInfo.division = res.data.data.address.division;
                            contactInfo.district = res.data.data.address.district;
                            contactInfo.thana = res.data.data.address.thana;
                            contactInfo.line = res.data.data.address.line;

                            getDivisionList();
                            getDistrictList();
                            getThanaList();
                        }
                    }
                })
                .catch((err) => {
                    pageLoading.value = false;
                    console.log(err);
                    showToast("error", "Error", "Could not fetch data. Try again!");
                });
        };
        getUserData();

        const getCountryList = () => {
            countryLoading.value = true;
            countryList.value = globalValues.value.getCountries();
            countryLoading.value = false;
        };

        const countryChanged = () => {
            contactInfo.thana = "";
            contactInfo.district = "";
            contactInfo.division = "";
            thanaList.value = [];
            districtList.value = [];
            divisionList.value = [];
            getDivisionList();
        };

        const getDivisionList = () => {
            divisionLoading.value = true;
            divisionList.value = globalValues.value.getDivisions(contactInfo.country);
            divisionLoading.value = false;
        };

        const divisionChanged = () => {
            contactInfo.district = "";
            contactInfo.thana = "";
            districtList.value = [];
            thanaList.value = [];
            getDistrictList();
        };

        const getDistrictList = () => {
            districtLoading.value = true;
            districtList.value = globalValues.value.getDistricts(contactInfo.division);
            districtLoading.value = false;
        };

        const districtChanged = () => {
            contactInfo.thana = "";
            getThanaList();
        };

        const getThanaList = () => {
            thanaLoading.value = true;
            thanaList.value = globalValues.value.getThanas(contactInfo.district);
            thanaLoading.value = false;
        };

        const savePersonalInfo = (isFormValid) => {
            personalInfoSubmitted.value = true;
            if (!isFormValid) {
                return;
            }
            personalInfoShowMessage.value = !personalInfoShowMessage.value;
            personalInfoLoading.value = true;

            Axios
                .post("api/user/update-personal-info", {
                    name: personalInfo.name,
                    mobile: personalInfo.mobile,
                    dob: personalInfo.dob == null ? null : moment(personalInfo.dob).format("YYYY-MM-DD"),
                    nid: personalInfo.nid,
                    passport: personalInfo.passport,
                    _method: "PUT",
                })
                .then((res) => {
                    if (res.data.response == "success") {
                        showToast("success", "Success", res.data.message);
                        personalInfoSubmitted.value = false;
                        personalInfoShowMessage.value = false;
                    } else {
                        showToast("error", "Error", res.data.message);
                    }
                    personalInfoLoading.value = false;
                })
                .catch(() => {
                    personalInfoLoading.value = false;
                    showToast("error", "Error", "Could not update personal information. Try again!");
                });
        };

        const saveAccountInfo = (isFormValid) => {
            accountInfoSubmitted.value = true;
            if (!isFormValid) {
                return;
            }
            accountInfoShowMessage.value = !accountInfoShowMessage.value;

            accountInfoLoading.value = true;

            Axios
                .post("api/user/update-account-info", {
                    password: accountInfo.password,
                    _method: "PUT",
                })
                .then((res) => {
                    if (res.data.response == "success") {
                        showToast("success", "Success", res.data.message);
                        accountInfoSubmitted.value = false;
                        accountInfoShowMessage.value = false;
                        accountInfo.password = "";
                    } else {
                        showToast("error", "Error", res.data.message);
                    }
                    accountInfoLoading.value = false;
                })
                .catch(() => {
                    accountInfoLoading.value = false;
                    showToast("error", "Error", "Could not update account information. Try again!");
                });
        };

        const saveContactInfo = (isFormValid) => {
            contactInfoSubmitted.value = true;
            if (!isFormValid) {
                return;
            }
            contactInfoShowMessage.value = !contactInfoShowMessage.value;
            contactInfoLoading.value = true;

            Axios
                .post("api/user/update-contact-info", {
                    country: contactInfo.country,
                    division: contactInfo.division,
                    district: contactInfo.district,
                    thana: contactInfo.thana,
                    line: contactInfo.line,
                    _method: "PUT",
                })
                .then((res) => {
                    if (res.data.response == "success") {
                        showToast("success", "Success", res.data.message);
                        contactInfoSubmitted.value = false;
                        contactInfoShowMessage.value = false;
                    } else {
                        showToast("error", "Error", res.data.message);
                    }
                    contactInfoLoading.value = false;
                })
                .catch(() => {
                    contactInfoLoading.value = false;
                    showToast("error", "Error", "Could not update contact information. Try again!");
                });
        };

        const onPhotoSelect = (event) => {
            photoToCrop.value = event.files[0].objectURL;
        };

        const onPhotoChange = ({  canvas }) => {
            photo.value = canvas;
        };

        const discardPhoto = () => {
            photo.value = null;
            selectedPhoto.value = null;
            photoToCrop.value = null;
        };

        const uploadPhoto = () => {
            profilePhotoLoading.value = true;
            const form = new FormData();
            photo.value.toBlob(blob => {
                form.append('photo', blob);
                form.append('_method', "PUT");
                Axios
                    .post("api/user/update-photo", form)
                    .then((res) => {
                        if (res.data.response == "success") {
                            showToast("success", "Success", res.data.message);
                            closePhotoDialog();
                            router.replace("/account");
                            pageLoading.value = true;
                            getUserData();
                        } else {
                            showToast("error", "Error", res.data.message);
                        }
                        profilePhotoLoading.value = false;
                    })
                    .catch(() => {
                        profilePhotoLoading.value = false;
                        showToast("error", "Error", "Could not update profile photo. Try again!");
                    });
            }, 'image/jpeg');
        };

        const closePhotoDialog = () => {
            changePhotoDialog.value = false;
            discardPhoto();
        }

        const sendVerificationEmail = () => {
            verificationLoading.value = true;
            Axios
                .post("api/user/send-verification-mail")
                .then((res) => {
                    if (res.data.response == "success") {
                        showToast("success", "Success", res.data.message);
                    } else {
                        showToast("error", "Error", res.data.message);
                    }
                    verificationLoading.value = false;
                })
                .catch(() => {
                    verificationLoading.value = false;
                    showToast("error", "Error", "Could not send verification email. Try again!");
                });
        };

        const showToast = (severity, summary, detail) => {
            toast.add({severity: severity, summary: summary, detail: detail, life: 5000});
        };

        const formatMobileNumber = (number) => {
            return '0' + number.toString().slice(-10);
        };

        return {
            pageLoading,
            verificationLoading,
            personalInfoLoading,
            contactInfoLoading,
            accountInfoLoading,
            profilePhotoLoading,
            constant,

            changePhotoDialog,

            countryLoading,
            divisionLoading,
            districtLoading,
            thanaLoading,
            user,

            personalInfoSubmitted,
            personalInfoShowMessage,
            contactInfoSubmitted,
            contactInfoShowMessage,
            accountInfoSubmitted,
            accountInfoShowMessage,


            personalInfo,
            personal$,

            accountInfo,
            account$,

            // contactInfo,
            contact$,
            countryList,
            divisionList,
            districtList,
            thanaList,
            contactInfo,

            photo,
            selectedPhoto,
            photoToCrop,

            getUserData,
            getCountryList,
            countryChanged,
            getDivisionList,
            divisionChanged,
            getDistrictList,
            districtChanged,
            getThanaList,
            savePersonalInfo,
            saveContactInfo,
            saveAccountInfo,
            onPhotoSelect,
            onPhotoChange,
            discardPhoto,
            uploadPhoto,
            closePhotoDialog,
            sendVerificationEmail,
            showToast,
            formatMobileNumber,
        }
    }
}
</script>
<style lang="scss" scoped>
.img-responsive {
    width: 100%;
    height: auto;
}
</style>
